const dataInEnglish = [
  {
    id: "01",
    isActive: true,
    name: "Carnaval de Negros y Blancos",
    city: "Pasto",
    cityDescription: `The capital of the department of Nariño will surprise you with its 
      beauty—in fact, Pasto is affectionately referred to as Colombia’s Surprise City. 
      Pasto is knows for its beautiful buildings, especially the churches that fill each street corner with a sense of austere beauty.`,
    state: "Nariño",
    festDate: 1,
    festDescription: "The Carnival of Blacks and Whites is the largest and most important festival in southern Colombia. It is celebrated every year in Pasto during the first week of January, attracting a large number of tourists from all corners of the country and abroad. The Carnival of Blacks and Whites has its origins in the fusion of multiple cultures and expressions from the Andes, the Amazon, and the Pacific culture. The carnival originated in the 16th century, in the year 1546. This fact distinguishes it from other similar expressions, starting with the date it is held, which has a purely indigenous origin, as it coincides with the celebration of the Moon (Quilla). This has ties to the rituals performed by the Pasto and Quillasinga peoples, agrarian cultures that, during harvest season, honored the moon with dances and, in other rituals, offered prayers to the sun to protect their crops.",
    activities: [
      "Offering to the Virgin of Las Mercedes",
      "Carnavalito",
      "Parade of Choreographic Groups",
      "Day of the Blacks",
      "Day of the Whites",
      "Concerts"
    ],
    imgPaths: "01-negros-blancos.jpg",
    bannerPath: "carnaval-negros-blancos.jpg",
    artistsList: [
      "La Tribu",
      "Trio Pambil",
      "Quinto Mandamiento",
      "Agrupsciones Musicales Románticas de la Región",
      "Las Añez",
      "Purik Dreams",
      "Descomunal",
      "Orquestas y Grupos Tropicales",
    ],
  },
  {
    id: "02",
    isActive: true,
    name: "Feria de Manizales",
    city: "Manizales",
    cityDescription: `
      Manizales is a Colombian municipality, the capital of the department of Caldas. It is located in the west-central region of Colombia, in the Coffee Axis, on the Central Andes Mountain Range, near the Nevado del Ruiz volcano. Known as "the city of open doors," this destination warmly welcomes local and international visitors with great energy. Exploring its natural attractions, its streets lined with beautiful traditional houses, and the places that bring the best coffee to life are perfect reasons not to miss an incredible journey through Manizales' tourist sites. A city that consistently offers perpetual snow, transitional rainforests, plants blooming year-round, and sunny, warm days that invite you to enjoy outdoor pools and walks through diverse landscapes inhabited by countless birds, beautiful insects, and most importantly, a warm and friendly human population.`,
    state: "Caldas",
    festDate: 1,
    festDescription: `The Manizales Fair is the first, largest, most important, and emblematic fair of the Colombian city of Manizales and the American continent. Centered around the Bullfighting Festival, which attracts major national and international figures with authentic bullfighting spectacles, the Manizales Fair is recognized for its bullfighting season, its events, concerts, exhibitions, and the International Coffee Pageant, as well as parades, musical performances of national folklore, and many other offerings in its extensive program filled with major events. It is worth noting that this annual celebration is considered the best, the only, and the great first fair of all of the Americas.
      This fair has Spanish roots and is inspired by the April Fair of Seville, Spain, adopting several of its customs, but also incorporating many traditions from the Coffee Region, especially those of the Andean region and "paisa" customs.`,
    activities: [
      "Bullfights",
      "International Coffee Pageant",
      "Handicraft Exhibitions",
      "Concerts"
    ],
    imgPaths: "02-feria-de-manizales.jpg",
    bannerPath: "feria-manizales.jpg",
    artistsList: [
      "Zion & Lenox",
      "Espinosa Paz",
      "La India",
      "Blessd",
      "Nelson Valásquez",
      "Rey Ruiz",
      "Yeison Jiménez",
      "Willy García",
      "Orquestas y Grupos Tropicales",
    ],
  },
  {
    id: "03",
    isActive: true,
    name: "Carnaval de Barranquilla",
    city: "Barranquilla",
    cityDescription: `Barranquilla is the capital of Atlántico despartment. Also known as "La Arenosa (The sandy)" is one of Colombia's major cities, 
      as well as a key tourist destination for locals and foreigners.`,
    state: "Atlántico",
    festDate: 2,
    festDescription: `The Barranquilla Carnival is a folkloric and cultural event in Colombia. Nearly 2 million people, including visitors and locals, participate in the festival annually. The Barranquilla Carnival is an event where all the cultural and folkloric expressions of the Colombian Caribbean coast are showcased, as well as a variety of local manifestations, such as popular music and dance. Many costumes evoke laughter and fright, representing all kinds of animal species, both native and exotic; African people; big-headed figures; madmen; giant dolls; superheroes; and mythological beings, transcending the limits of sexuality and death, among an almost endless number of popular inventions and creations. Some of the most traditional costumes of the Barranquilla Carnival are the Marimonda, the Garabato, the Congo, and the Monocuco.
      The Barranquilla Carnival has been declared by UNESCO as a Masterpiece of the Oral and Intangible Heritage of Humanity.`,
    activities: [
      "Parades with folkloric groups, orchestras, queens, Tradition and Fantasy troupes",
      "Dance in the Street",
      "Concerts"
    ],
    imgPaths: "03-carnaval-de-barranquilla.png",
    bannerPath: "carnaval-barranquilla.png",
    artistsList: [
      "Wisin y Yandel",
      "Juan Luis Guerra",
      "Ryan Castro",
      "Peter Manjarres",
      "Diego Daza",
      "Maelo Ruiz",
      "Festival de Orquestas",
    ],
  },
  {
    id: "04",
    isActive: true,
    name: "Festival Nacional de la Música Colombiana",
    city: "Ibagué",
    cityDescription: `Ibagué is a Colombian municipality located in the central-western region of Colombia, on the Central Andes Mountain Range. It is the capital of the department of Tolima.
      It is known as The Musical Capital of Colombia, a title given by the French Count of Gabriac in his travel chronicles published in Europe around 1886, who was impressed by the city's musical atmosphere, the colorful murals of the downtown buildings, and the Tolima Conservatory, considered one of the most important music schools in the country. Ibagué has a wide variety of national and ecological parks that you can visit to appreciate the region's flora and fauna.`,
    state: "Tolima",
    festDate: 3,
    festDescription: `Since its creation on March 21, 1987, as a tribute to the duet Garzón y Collazos, it has been held annually without interruption. This National Festival was institutionalized with the purpose of perpetuating the artistic legacy of the immortal duet "Garzón y Collazos." Following in their footsteps and inspired by their influence, many other duets have formed in Tolima and across the country, working daily to preserve Colombia's musical heritage.`,
    activities: [
      "National Duet Competition",
      "Princes of the Song",
      "National Composition Competition"
    ],
    imgPaths: "04-festival-musica-colombiana.jpg",
    bannerPath: "festival-nacional-musica-colombiana.png",
    artistsList: ["Artists and composers of Colombian folklore and music"],
  },
  {
    id: "05",
    isActive: true,
    name: "Paipa Color Festival",
    city: "Paipa",
    cityDescription: `Paipa (originally Villa de San Miguel Arcángel de Paipa) is a Colombian municipality located in the central-eastern region of Colombia.
      Paipa is a town in Boyacá, known for its tourism, famous for its thermal waters, which are believed to have therapeutic benefits, its mild climate, and the beauty of its landscapes. In addition to these features, Paipa is known for typical products such as almojábana, yuca bread, and other local specialties.
      It is considered the municipality in Boyacá with the best hotel infrastructure in the department, and due to its tranquility and location, it is ideal for rest and recreation, as well as for hosting conferences and seminars.`,
    state: "Boyacá",
    festDate: 8,
    festDescription: `Since 2016, the Paipa Color Festival has been held, where all attendees can participate in an atmosphere filled with fun, electronic music, and lots of color. Paipa Color Festival is an event full of color that is celebrated every year, creating spaces for integration, equality, recreation, and community.
      Inspired by India's Holi festival, it always features performances by prominent electronic music artists in Colombia, showcasing the best sound and light displays, while the audience, in a state of euphoria, continuously throws colored powder. There are different areas for the public to enjoy: an integration zone, a love zone, a sports zone, and a craft market.`,
    activities: [
      "Electronic Music, Rap, Rock Concerts",
      "Extreme Sports",
      "Craft Market"
    ],
    imgPaths: "05-paipa-color-festival.jpg",
    bannerPath: "paipa-color-festival.png",
    artistsList: ["Electronic music", "Local DJs"],
  },
  {
    id: "06",
    isActive: true,
    name: "Festival de la Leyenda Vallenata",
    city: "Valledupar",
    cityDescription: `Valledupar, also known as the City of the Holy Kings of the Valley of Upar, is a Colombian municipality and the capital of the department of Cesar. The city is an important center for agricultural, agro-industrial, and livestock production in the region between the northern part of Cesar and the southern part of La Guajira, situated between the two largest coal mining basins in the country.
      It is also one of Colombia's main musical, cultural, and folkloric hubs, as it is the birthplace of vallenato, the most popular musical genre in the country and currently a symbol of Colombian music. Every year, it attracts thousands of visitors from Colombia and abroad during the Festival of the Vallenato Legend, the most important event of the vallenato genre.`,
    state: "Cesar",
    festDate: 4,
    festDescription: `In 1968, three people thought it was time to do something to ensure that all this cultural and musical heritage would not fade away into the mists of time. They decided to create the Vallenato Legend Festival to recreate the magic of a land where myths, customs, personal experiences, and a rich linguistic and oral tradition nourish the literature and the music where the lyrics and melodies of vallenato are woven.
      The 1999 Vallenato Legend Festival undoubtedly marked a milestone in its history, as the television broadcast was aired nationally and internationally for the first time. During this festival, five "Lifetime Kings" were crowned, selected for representing different Vallenato schools from the Atlantic Coast and for their careers as renowned accordionists, which categorized them as true minstrels. The three schools were represented as follows: the "Vallenato-Bajero" from the Magdalena and Bolívar regions, with Francisco "Pacho" Rada and Abel Antonio Villa; the "Vallenato-Sabanero" from Sucre and Córdoba, with Andrés Landero; and the "Vallenato-Vallenato" from Cesar and La Guajira, with Antonio Salas and Lorenzo Morales. These events undoubtedly increased the significance and projection of this festival, fully meeting the expectations of the fans of this folklore.`,
    activities: [
      "Accordionists Competition, Children's, Youth Amateur, and Adult Categories",
      "Piqueria Competition",
      "Musical, Cultural, and Folkloric Show - Concert"
    ],
    imgPaths: "06-festival-Leyenda-Vallenata.jpg",
    bannerPath: "festival-leyenda-vallenata.png",
    artistsList: [
      "Juan Luis Guerra",
      "Grupo Frontera",
      "Iván Villazón",
      "Peter Manjarrés",
      "Elder Dayán"
    ],
  },
  {
    id: "07",
    isActive: true,
    name: "Fiestas del Mar",
    city: "Santa Marta",
    cityDescription: `Santa Marta is the capital of the Magdalena department in Colombia. It is known for its tourist activities, the history of its streets, and its beaches. The El Rodadero resort is one of the main destinations in the Colombian Caribbean. Its urban area lies between the Sierra Nevada de Santa Marta and the Caribbean Sea, and the Tayrona Park is under its jurisdiction. Among its cultural and historical attractions are the Customs House, the Basilica Cathedral, the Bank of the Republic Library, the San Juan Nepomuceno Seminary, the Bay Boardwalk, the Quinta de San Pedro Alejandrino, Bolívar Square, and Los Novios Park.`,
    state: "Magdalena",
    festDate: 7,
    festDescription: `Every year, at the end of July, Santa Marta dresses up in style to celebrate the Fiesta del Mar. It is a major tourist showcase, with water sports as the main attraction, accompanied by various cultural activities, music, dancing, and entertainment.`,
    activities: [
      "National Nautical and Beach Games",
      "National and International Sea Pageant",
      "Cultural Activities",
      "Dancing, Music, and Entertainment"
    ],
    imgPaths: "07-fiestas-mar.jpg",
    bannerPath: "fiestas-mar.png",
    artistsList: ["Silvestre Dangond", "Beto Zabaleta", "Poncho Zuleta", "Peter Manjarrés", "Iván Villazón", "Elder Dayan", "Koffe El Kafetero", "GiBlack", "Michell Torres", "La Big Band Samaria"],
  },
  {
    id: "08",
    isActive: false,
    name: "Festival Cartagena Pride",
    city: "Cartagena",
    cityDescription: `Todo`,
    state: "Bolivar",
    festDate: 8,
    festDescription: `Todo`,
    activities: ["sample 1", "sample 2"],
    imgPaths: "08-festival-cartagena-pride",
    artistsList: ["Música Electrónica", "DJs Locales"],
  },
  {
    id: "09",
    isActive: true,
    name: "Feria de Flores",
    city: "Medellin",
    cityDescription: `Medellín, better known as the City of Eternal Spring, is the capital of the Antioquia department. Located in a valley (Valle de) surrounded by mountains with a pleasant climate, Medellín is a captivating city, not only because of its beautiful landscapes but also due to the friendliness of its people, its delicious food, and the charming "paisa" culture that enchants visitors.`,
    state: "Antioquia",
    festDate: 8,
    festDescription: `Feria de las Flores (Flower Festival) is a festivity you won’t want to miss if you want to experience the blend of tradition and modernity that has made Medellín an example of growth and innovation worldwide.
      This celebration, which began in 1957, is a ten-day tribute to the beautiful flowers grown in the region. It is the most iconic festival of the city and has become a cultural, historical, and root symbol, similar to the Rio Carnival in Rio de Janeiro, Brazil; Oktoberfest in Bavaria, Germany; or, in Colombia, the Manizales Fair, the Barranquilla Carnival, the Cali Fair, and the Black and White Carnival in the city of San Juan de Pasto.
      The carnival atmosphere in Medellín during these days offers a wide variety of events and shows, not all related to flowers. However, the general name "Feria de las Flores" reflects that flowers are the centerpiece of the festivities, offering the most striking and beautiful scenes, unique in the world.`,
    activities: [
      "Parade of Silleteros",
      "Parade of Classic and Antique Cars",
      "National Trova Festival",
      "Gastronomic Exhibitions",
      "National Horse Fair",
      "National Sound on Wheels Championship",
      "Cultural Activities Native to the Region",
      "Concerts"
    ],
    imgPaths: "09-feria-flores.jpg",
    bannerPath: "feria-flores.png",
    artistsList: [
      "Jorge Celedón", 
      "Pipe Peláez",
      "Arelys Henao",
      "Jean Carlos Centeno",
      "Daniel Calderón",
      "Mauro Castillo ",
      "Hebert Vargas",
      "Pipe Bueno",
      "Binomio de Oro",
      "El Combo de las Estrellas",
      "Dálmata",
    ],
  },
  {
    id: "10",
    isActive: true,
    name: "Feria de Cali",
    city: "Santiago de Cali",
    cityDescription: `Santiago de Cali, the capital of the Valle del Cauca department, is the third most populous city and the third largest economic and cultural center in Colombia. It is located in the southern region of Valle del Cauca. Cali is a city with tourist attractions steeped in history, a vibrant cultural life, and musical rhythms that have gained it worldwide fame.
      For this reason, Cali is distinguished in Colombia as the 'Capital of the Party' and, internationally, as the 'Capital of Salsa,' because street parties and dancing are its defining characteristics.`,
    state: "Valle del Cauca",
    festDate: 12,
    festDescription: `This event, held in the capital of Valle del Cauca, is a tribute to flavor and rhythm, especially to salsa, the genre that has brought worldwide fame to this city. In fact, Cali is known as the 'World Capital of Salsa.'
      The Cali Fair, which takes place in the last week of December with the intention of ending the year on a high note, usually offers more than 50 events for locals and tourists of all ages and tastes to fully enjoy this grand celebration.`,
    activities: [
    "Horse Parade",
    "Salsódromo",
    "Cali Viejo Carnival",
    "Parade of Classic and Antique Cars",
    "Bullfighting Fair",
    "Meeting of Salsa Enthusiasts, Melomaniacs, and Collectors",
    "Concerts"
    ],
    imgPaths: "10-feria-de-cali.jpg",
    bannerPath: "feria-de-cali.png",
    artistsList: [
      "Willie Colón",
      "Grupo Niche",
      "Oscar D'León",
      "Víctor Manuelle",
      "Rey Ruiz",
      "Gilberto Santa Rosa",
      "Guayacán Orquesta",
      "Mayito Rivera",
      "Willy García",
      "Javier Vásquez",
      "Jimmy Saa",
      "Ariacne Trujillo (Cuba)",
      "Mayito Rivera (Cuba) - Orquesta Kimbara",
      "Froyber Maya (Cali)",
      "Runa Pakary",
    ],
  },
  {
    id: "11",
    isActive: true,
    name: "Rock al Parque",
    city: "Bogotá",
    cityDescription: `Bogotá is the capital of the Republic of Colombia and the department of Cundinamarca.
      It is the third-highest capital in the world (after Quito and La Paz), as it sits at an average of 2,625 meters above sea level. It is located in the center of Colombia, in the natural region known as the Bogotá savanna, which is part of the Cundiboyacense high plateau, situated in the Eastern Cordillera of the Andes.
      Bogotá is a meeting point for people from all over the country, making it diverse and multicultural, where the old and the modern blend together. Thanks to this fusion of past and present, the capital offers an ideal destination with history, entertainment, gastronomy, culture, business, and much more.`,
    state: "Cundinamarca",
    festDate: 11,
    festDescription: `Rock al Parque is an international rock festival held in Bogotá, Colombia, since 1994. It is the largest free, open-air festival in Latin America and the third largest in the world. The festival has hosted both world-renowned artists and emerging talents just starting their careers. Additionally, it is one of the "Festivales al Parque," which feature various musical genres such as jazz, hip hop, and salsa.
      Some of the artists and bands that have performed at the festival include: Fito Páez (Argentina), Christina Rosenvinge (Spain), Molotov (Mexico), Los Auténticos Decadentes (Argentina), Café Tacuba (Mexico), Aterciopelados (Colombia), Zona Ganjah (Argentina), Manu Chao (France), La Fuga (Spain), Los Amigos Invisibles (Venezuela), and Juanes (Colombia).`,
    activities: [
      "Concerts",
      "Cultural Activities",
      "Special Rock al Parque Talks"
    ],
    imgPaths: "11-rock-al-parque.jpg",
    bannerPath: "rock-al-parque.jpg",
    artistsList: [
      "In Flames",
      "Overkill",
      "Los Calzones",
      "Sonido Gallo",
      "Cabra",
      "Chemicide",
      "Javiera Mena",
      "Marilina Bertoldi",
      "Nonpalidece",
      "Los Punsetes",
      "Noprocede",
      "Jenny Woo",
      "Ensamble Arsis", 
      "Latenaz",
      "No Dependiente",
      "Munnopsis",
      "Alto Grado"
    ],
  },
  {
    id: "12",
    isActive: true,
    name: "Altavoz",
    city: "Medellín",
    cityDescription: `Medellín, better known as the City of Eternal Spring, is the capital of the Antioquia department. Located in a valley (Valle de) surrounded by mountains with a pleasant climate, Medellín is a captivating city, not only because of its beautiful landscapes but also due to the friendliness of its people, its delicious food, and the charming "paisa" culture that enchants visitors.`,
    state: "Antioquia",
    festDate: 10,
    festDescription: "The Altavoz International Festival is a rock festival held in the city of Medellín, Colombia, since 2004. The festival was born from the idea of creating a space for young people and local musicians to showcase their music.",
    activities: [
      "Concerts",
      "Cultural Activities"
      ],
    imgPaths: "12-altavoz.jpg",
    bannerPath: "altavoz.png",
    artistsList: [
      "In Flames",
      "Overkill",
      "Los Calzones",
      "Sonido Gallo",
      "Cabra",
      "Chemicide",
      "Javiera Mena",
      "Marilina Bertoldi",
      "Nonpalidece",
      "Los Punsetes",
      "Noprocede",
      "Jenny Woo",
      "Ensamble Arsis", 
      "Latenaz",
      "No Dependiente",
      "Munnopsis",
      "Alto Grado"
    ],
  },
  {
    id: "13",
    isActive: true,
    name: "Festival Estéreo Picnic",
    city: "Bogotá",
    cityDescription: `Bogotá is the capital of the Republic of Colombia and the department of Cundinamarca.
      It is the third-highest capital in the world (after Quito and La Paz), as it sits at an average of 2,625 meters above sea level. It is located in the center of Colombia, in the natural region known as the Bogotá savanna, which is part of the Cundiboyacense high plateau, situated in the Eastern Cordillera of the Andes.
      Bogotá is a meeting point for people from all over the country, making it diverse and multicultural, where the old and the modern blend together. Thanks to this fusion of past and present, the capital offers an ideal destination with history, entertainment, gastronomy, culture, business, and much more.`,
    state: "Cundinamarca",
    festDate: 3,
    festDescription: `Festival Estéreo Picnic is a music festival held in the Bogotá savanna, featuring national and international artists offering music in genres such as rock, electronic, pop, and new folk, among many others. The event also includes cultural and entertainment activities, in addition to the concerts, where attendees can enjoy food and outdoor art. With the 2018 edition, the festival has held nine installments, during which the organizers have aimed to create a musical experience featuring internationally, nationally, and locally renowned artists. The festival also includes parallel activities such as a commercial exhibit called "Hippie Market" and plenty of space for interaction among attendees, in the style of major world festivals like Coachella and Lollapalooza.`,
    activities: [
      "Concerts",
      "Cultural and Entertainment Activities",
      "Hippie Market"
    ],
    imgPaths: "13-estereo-picnic.jpg",
    bannerPath: "estereo-picnic.png",
    artistsList: [
      "Justin Timberlake",
      "Incubus",
      "Benson Boone",
      "Shawn Mendes",
      "Foster the People",
      "Tools",
      "Alanis Morissette",
      "The Black Keys",
      "Justice",
      "Zedd",
      "Olivia Rodrigo",
      "Mon Laferte",
      "Rüfüs Du Sol",
      "LosPetitFellas",
      "Tate McRae",
      "Parcels",
      "The Hives",
      "Empire of the Sun"
    ],
  },
    {
    id: "14",
    isActive: true,
    name: "Festival Cordillera",
    city: "Bogotá",
    cityDescription: `Bogotá is the capital of the Republic of Colombia and the department of Cundinamarca.
      It is the third-highest capital in the world (after Quito and La Paz), as it sits at an average of 2,625 meters above sea level. It is located in the center of Colombia, in the natural region known as the Bogotá savanna, which is part of the Cundiboyacense high plateau, situated in the Eastern Cordillera of the Andes.
      Bogotá is a meeting point for people from all over the country, making it diverse and multicultural, where the old and the modern blend together. Thanks to this fusion of past and present, the capital offers an ideal destination with history, entertainment, gastronomy, culture, business, and much more.`,
    state: "Cundinamarca",
    festDate: 9,
    festDescription: `The Cordillera Festival is a music event held in Bogotá, Colombia, since 2022. Its main focus is to highlight Latin American talent, bringing together artists from various musical genres with a strong emphasis on rock, indie, pop, and alternative music. The name "Cordillera" is inspired by the geography of the Andean region, symbolizing the connection and unity among Latin American countries through music and culture.
      The festival takes place at Parque Simón Bolívar, one of Bogotá’s largest outdoor event venues, and has gained recognition for featuring both big names in Latin American music and emerging artists. In addition to its musical focus, the Cordillera Festival aims to be a sustainable event, with initiatives to reduce its environmental impact.`,
    activities: [
      "Concerts",
      "Cultural and Entertainment Activities",
    ],
    imgPaths: "14-fest-cordillera.png",
    bannerPath: "festival-cordillera.jpeg",
    artistsList: [
      "Juan Luis Guerra 4.40",
      "Hombres G",
      "Miranda!",
      "Vilma Palma e Vampiros",
      "Los Fabulosos Cadillacs",
      "Fonseca",
      "Molotov",
      "Rels B",
      "Babasonicos",
      "Maldita Vecindad",
      "Nicolle Jadad",
      "Bersuit Vergarabat",
      "Morodo & Okoumé Lions"
    ],
  },
];

export default dataInEnglish;
